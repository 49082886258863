import React, { useState } from 'react';
import '../styles/Services.css';
import serviceImage1 from '../assets/service.png';
import electricalImg from '../assets/elec.jpg';
import htltImg from '../assets/htlt.png';
import securityImg from '../assets/cctv.jpg';
import WarehouseImg from '../assets/warehouse.jpg';
import lightingImg from '../assets/lighting.jpg';
import firefightingImg from '../assets/firefighting.jpg';
import Brochure from '../assets/Brochure.pdf';
import brochureIcon from '../assets/brochure-icon.png';

const Services = () => {
    const [selectedService, setSelectedService] = useState(null);

    const services = [
        {
            name: "Electrical Services",
            image: electricalImg,
            description: "We offer comprehensive electrical solutions including installations, repairs, and maintenance for residential, commercial, and industrial applications.",
            details: (
                <>
                    <ul>
                        <li>Electrical Panel</li>
                        <li>Electrical Energy Audit</li>
                        <li>Power Distribution and Management</li>
                        <li>Installation Services</li>
                        <li>Maintenance and Repair Services</li>
                        <li>Safety and Compliance</li>
                        <li>Energy Management</li>
                        <li>Control Systems and Automation</li>
                        <li>Security and Surveillance Systems</li>
                        <li>Emergency Services</li>
                        <li>Consulting and Design Services</li>
                        <li>Training and Certification</li>
                        <li>Project Management</li>
                    </ul>
                </>
            )
        },
        {
            name: "HT / LT Services",
            image: htltImg,
            description: "Specialized high tension (HT) and low tension (LT) electrical services for industrial and commercial setups.",
            details: (
                <>
                    <ul>
                        <li>HT Panel Installation</li>
                        <li>LT Panel Installation</li>
                        <li>Transformer Installation</li>
                        <li>Cable Laying and Termination</li>
                        <li>System Upgrades and Maintenance</li>
                        <li>Safety and Compliance Testing</li>
                    </ul>
                </>
            )
        },
        {
            name: "Warehouse Works",
            image: WarehouseImg,
            description: "Electrical solutions tailored for warehouse environments, including lighting, power distribution, and safety systems.",
            details: (
                <>
                    <ul>
                        <li>Energy-efficient Lighting Installation</li>
                        <li>Power Distribution Systems</li>
                        <li>Backup Power Systems</li>
                        <li>Fire and Safety Installations</li>
                        <li>Maintenance and Repair Services</li>
                    </ul>
                </>
            )
        },
        {
            name: "E - Security System Works",
            image: securityImg,
            description: "Installation and maintenance of electronic security systems including CCTV, alarms, and access control systems.",
            details: (
                <>
                    <ul>
                        <li>CCTV Surveillance Installation</li>
                        <li>Access Control Systems</li>
                        <li>Intrusion Alarm Systems</li>
                        <li>Remote Monitoring Solutions</li>
                        <li>Maintenance and Repairs</li>
                    </ul>
                </>
            )
        },
        {
            name: "Lighting Works",
            image: lightingImg,
            description: "Design and installation of lighting systems for residential, commercial, and industrial spaces.",
            details: (
                <>
                    <ul>
                        <li>Architectural Lighting Design</li>
                        <li>LED and Energy-saving Solutions</li>
                        <li>Outdoor and Indoor Lighting</li>
                        <li>Lighting Control Systems</li>
                        <li>Lighting Upgrades and Retrofitting</li>
                    </ul>
                </>
            )
        },
        {
            name: "Fire Fighting Works",
            image: firefightingImg,
            description: "Installation and maintenance of fire fighting systems including sprinklers, alarms, and fire extinguishers.",
            details: (
                <>
                    <ul>
                        <li>Fire Alarm Systems</li>
                        <li>Sprinkler Installation</li>
                        <li>Fire Extinguishers</li>
                        <li>Fire Hydrant Systems</li>
                        <li>Maintenance and Compliance Checks</li>
                    </ul>
                </>
            )
        }
    ];

    const handleServiceClick = (service) => {
        setSelectedService(service);
    };

    const handleClosePopup = () => {
        setSelectedService(null);
    };

    return (
        <div className="services-page">
            <div className="main-photo">
                <img src={serviceImage1} alt="Main" />
            </div>
            <div className="services">
                {services.map((service, index) => (
                    <div key={index} className="service-item" onClick={() => handleServiceClick(service)}>
                        <img src={service.image} alt={service.name} className="service-image1" />
                        <div className="service-text">{service.name}</div>
                    </div>
                ))}
            </div>

            {/* Service Details Popup */}
            <div className={`popup ${selectedService ? 'visible' : ''}`}>
                <div className="popup-content">
                    <span className="close-btn" onClick={handleClosePopup}>&times;</span>
                    {selectedService && (
                        <>
                            <h2>{selectedService.name}</h2>
                            <div className="popup-details">
                                {selectedService.details}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Brochure Download Section */}
            <div className="brochure-download">
                <img src={brochureIcon} alt="Brochure Icon" className="brochure-icon" />
                <div className="brochure-text">
                    <p className="brochure-title">Our Profile</p>
                    <p>Download our Brochure to know more about our services</p>
                </div>
                <a href={Brochure} download>
                    <button className="download-button">Download</button>
                </a>
            </div>
        </div>
    );
};

export default Services;
