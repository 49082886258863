import React, { useEffect, useState } from 'react';
import '../styles/Aboutusfull.css';
import AboutusImage from '../assets/Aboutus.png';
import ProfileImage from '../assets/ab.jpg';
import VisionImage from '../assets/Vission.png'; 
import MissionImage from '../assets/Mision.png';
import ValuesImage from '../assets/Valu.png';
import SupplyImage from '../assets/Supply.jpg';
import InstallationImage from '../assets/Installation.jpg';
import TestingImage from '../assets/Testing.jpg';
import CommissioningImage from '../assets/Commisioning.jpg';

const Aboutus = () => {
    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.about-us-container, .vmv-box, .step');
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;

            elements.forEach((el, index) => {
                const elementTop = el.getBoundingClientRect().top + scrollTop;
                if (scrollTop + windowHeight > elementTop) {
                    el.style.opacity = '1';
                    el.style.animation = `fadeInUp 1s ease forwards ${index * 0.2}s`;
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState({});

    const handlePopupOpen = (content) => {
        setPopupContent(content);
        setIsPopupVisible(true);
    };

    const handlePopupClose = () => {
        setIsPopupVisible(false);
    };

    return (
        <div className="main-photo">
            <img src={AboutusImage} alt="Banner" className="banner-image" />
            <div className="about-us-container">
                {/* Two Divisions */}
                <div className="two-divisions">
                    <div className="left-side">
                        <img src={ProfileImage} alt="About Us" className="division-image"/>
                    </div>
                    <div className="right-side">
                        <div className="welcome-text">
                            Welcome to <span className="blue-letter">F</span>IVEBRO
                        </div>
                        <p className="light-black-text">Fivebro Electrical Solution is a startup founded by a group of like-minded friends, each bringing extensive experience across residential, commercial, and industrial sectors. We proudly serve as your one-stop solution for all electrical, e-security system, and fire fighting service needs.</p>
                        <p className="light-black-text">Operating throughout Karnataka and Andhra Pradesh, we are dedicated to excellence and innovation in our field. Although we are a new entrant in the industry, our team of highly trained technicians and engineers excels in managing sophisticated electrical systems and installations.</p>
                        <p className="light-black-text">Our mission at Fivebro Electrical Solution is to challenge conventional boundaries and redefine the possibilities of electrical technology. We are committed to delivering innovative solutions that enhance efficiency, safety, and functionality.</p>
                        <p className="light-black-text">We believe that the key to our success lies in forming strong partnerships and collaborations, which we see as the foundation for ongoing growth and excellence. Join us on this exciting journey as we set new standards in the industry.</p>
                    </div>
                </div>

                {/* Vision, Mission, Values */}
                <div className="vmv-container">
                    <div className="vmv-box">
                        <img src={VisionImage} alt="Vision" className="vmv-image"/>
                        <h3>Vision</h3>
                        <p>To establish ourselves as the top choice for our customers' electrical service and product needs.</p>
                    </div>
                    <div className="vmv-box">
                        <img src={MissionImage} alt="Mission" className="vmv-image"/>
                        <h3>Mission</h3>
                        <p>Enhanced products and services empower customers, employees, vendors, and all stakeholders to elevate life.</p>
                    </div>
                    <div className="vmv-box">
                        <img src={ValuesImage} alt="Values" className="vmv-image"/>
                        <h3>Values</h3>
                        <p>Quality, safety, excellence, professionalism, ethics, teamwork.</p>
                    </div>
                </div>

                {/* Working Procedure */}
                <div className="working-procedure">
                    <h2>Working Procedure</h2>
                    <div className="procedure-steps">
                        <div className="step" onClick={() => handlePopupOpen({ title: 'Supply', image: SupplyImage, description: 'Supply: We handle the procurement and supply of high-quality electrical components and systems, ensuring that all materials meet stringent standards for performance and reliability.' })}>
                            <img src={SupplyImage} alt="Supply" className="step-image"/>
                            <h3>Supply</h3>
                            <p>Procurement and supply of quality electrical components.</p>
                        </div>
                        <div className="step" onClick={() => handlePopupOpen({ title: 'Installation', image: InstallationImage, description: 'Installation: Our team provides expert installation services for electrical systems, ensuring that every component is properly set up and meets safety and performance standards.' })}>
                            <img src={InstallationImage} alt="Installation" className="step-image"/>
                            <h3>Installation</h3>
                            <p>Expert installation of electrical systems.</p>
                        </div>
                        <div className="step" onClick={() => handlePopupOpen({ title: 'Testing', image: TestingImage, description: 'Testing: We conduct thorough testing of electrical systems to ensure their functionality, safety, and compliance with regulations before they are fully operational.' })}>
                            <img src={TestingImage} alt="Testing" className="step-image"/>
                            <h3>Testing</h3>
                            <p>Thorough testing for functionality and safety.</p>
                        </div>
                        <div className="step" onClick={() => handlePopupOpen({ title: 'Commissioning', image: CommissioningImage, description: 'Commissioning: We finalize and commission electrical systems, making sure they are fully operational and meet all performance criteria.' })}>
                            <img src={CommissioningImage} alt="Commissioning" className="step-image"/>
                            <h3>Commissioning</h3>
                            <p>Finalization and commissioning of systems.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Popup */}
            {isPopupVisible && (
                <div className="popup visible">
                    <div className="popup-content">
                        <span className="close-btn" onClick={handlePopupClose}>&times;</span>
                        <h2>{popupContent.title}</h2>
                        <img src={popupContent.image} alt={popupContent.title} className="popup-image"/>
                        <p>{popupContent.description}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Aboutus;
